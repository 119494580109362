@import "./Button.scss";
@import "./Socials.scss";
@import "./Table.scss";

#auth {
    color: #4f5152;

    #shadow {
        padding: 12% 15% !important;

        @media all and (min-width: 678px) {
            box-shadow: 0px 0px 10px #ccc;
        }

        .logo {
            max-width: 250px;
            width: 100%;
        }
    }
}

#dashboard {
    padding: 2% !important;

    @media all and (min-width: 678px) {
        box-shadow: 0px 0px 10px #ccc;
    }

    .content-contratos {
        .error {
            border: solid 1px #ff0000;
        }

        &__detalle {
            .cabecera {
                background-color: #627171;
                padding: 25px;
                font-size: 18px;
                color: #fff;
                margin: 30px 0;
                cursor: pointer;
                position: relative;

                .btn {
                    position: absolute;
                    top: 16px;
                    right: 5px;

                    img {
                        width: 25px;
                        transform: rotate(0deg);
                    }
                }
            }
        }

        .open-collapse {
            .icon-action-accordeon {
                transform: rotate(45deg) !important;
            }
        }

        .total {
            background-color: #F2F2F2;
            color: #627171;
            padding: 30px;
            margin: 20px 0;
            font-weight: 400 !important;
            font-size: 26px;
            text-align: center;
        }

        .checkout {
            p {
                margin: 10px 0 !important;
            }

            .email {
                margin: 10px 0 !important;
                background-color: #f1f1f1;
            }
        }

        .contact {
            .content_info {
                h5 {
                    color: #627171;
                    font-weight: bold !important;
                }

                img {
                    max-width: 45%;
                }
            }
        }

        .btn-verde {
            margin: 10px 0;
            padding: 10px 10%;
        }
    }

}

p {
    color: #4f5152;
    font-weight: 300;
}

h4,
h2 {
    color: #4f5152 !important;
    font-weight: 500 !important;
    text-align: center;
    font-size: 24px !important;
}

.spinner {
    margin: 30px 0;
}

.mnt-opcion {
    input {
        zoom: 2;
    }

    .pantalla {
        position: relative;

        &.verifica {
            display: none;

            .bloque-pago {
                position: absolute;


                &.inactivo {
                    background-color: #d4d4d473;
                    border: solid 1px #dcdcdc;
                    width: 100%;
                    height: 100%;
                    display: none !important;
                }
            }
        }
    }
}

.detalle {
    margin-top: 30px;
    margin-bottom: 30px;

    .text-title-detalle {
        margin: 10px 0 !important;

        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: bold !important;
            line-height: 33px;
        }
    }

    .mis-datos {
        font-weight: bold !important;
        font-size: 18px !important;
    }


    .back-detalle {
        background-color: #fff !important;

        th,
        td {
            font-size: 14px !important;
        }
    }
}

a {
    text-decoration: none !important;

    .phone {
        font-size: 32px;
        font-weight: bold;
        color: #811e6c;
        letter-spacing: 1px;
    }
}